import api from "./api";

const userInfo = JSON.parse(localStorage.getItem('userinfo') || '{}');

export default {
  addSmarturl(data) {
    // return api.execute(`post`, `/moderator/smarturl`, data);
    return api.execute(`post`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/smarturl`, data);

  },
  getSmarturls(payload = {}) {
    const queryString = new URLSearchParams(payload).toString();
    // return api.execute(`get`, `/moderator/smarturl?${queryString}`);
    return api.execute(`get`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/smarturl?${queryString}`);
  },

  deleteSmartUrls(data) {
    // return api.execute(`delete`, `/moderator/smarturl`, data);
    return api.execute(`delete`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/smarturl`, data);

  },
  editSmarturl(data) {
    // return api.execute(`put`, `/moderator/smarturl`, data);
    return api.execute(`put`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/smarturl`, data);

  },
  csvUpload(data) {
    // return api.execute(`post`, `/moderator/smarturl/csv`, data);
    return api.execute(`post`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/smarturl/csv`, data);
  },
};
