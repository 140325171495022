<template>
  <div>
    <b-row>
      <b-col class="col-md-6 col-12">
        <Breadcrumbs main="" title="Smart Url" class="showBreadcrumbs" />
        <!-- <Breadcrumbs main="" title="Smart Url" :display="isBreadView" /> -->
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <b-card title="" type="gradient-red" sub-title="" class="mb-4">
          <b-row class="align-items-start mb-4">
            <b-col class="col-md-3 col-12 mt-1 date-range-custom">
              <b-form-group label="Search Name">
                <b-input-group class="">
                  <b-input-group-prepend>
                    <span class="input-group-text search-bg"
                      ><i class="icofont icofont-search-alt-2"></i
                    ></span>
                  </b-input-group-prepend>
                  <b-form-input
                    class="border-left-0"
                    v-model="filterAllData.name"
                    placeholder="Search name "
                    type="search"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col class="col-md-3 col-12 mt-1 date-range-custom">
              <b-form-group label="Search Url">
                <b-input-group class="">
                  <b-input-group-prepend>
                    <span class="input-group-text search-bg"
                      ><i class="icofont icofont-search-alt-2"></i
                    ></span>
                  </b-input-group-prepend>
                  <b-form-input
                    class="border-left-0"
                    v-model="filterAllData.url"
                    placeholder="Search keyword in url"
                    type="search"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col class="col-md-3 col-12">
              <b-form-group label="Country">
                <multiselect
                  :options="countryOpt"
                  label="name"
                  v-model="filterAllData.country"
                  placeholder="Select country"
                  :multiple="false"
                  :close-on-select="true"
                  track-by="id"
                ></multiselect>
              </b-form-group>
            </b-col>

            <b-col class="d-flex mt-4">
              <button
                @click="filterData"
                class="btn custom-btn"
                style="margin-top: 1vh"
              >
                Filter
              </button>
              <button
                @click="init()"
                class="ml-2 btn custom-btn"
                style="margin-top: 1vh"
              >
                Reset
              </button>
            </b-col>
          </b-row>

          <b-row class="align-items-start">
            <b-col class="col-md-3 col-12">
              <span class="custome-title">Smart Url</span>
            </b-col>

            <b-col
              class="col-md-9 col-12 d-flex justify-content-end align-items-start"
            >
              <b-form-group
                label-cols="5"
                label="Per page"
                class="col-3 md-0 custom-font-18 text-right"
              >
              
                <b-form-select
                  class="border custom-font-24"
                  v-model="perPage"
                  :options="pageOptions"
                  @change="handlePerPageChange"
                >
                </b-form-select>
              </b-form-group>
              <button
                class="btn mr-2 mb-2 custom-btn-2"
                @click="open_import_smartUrl = true"
              >
                Bulk Import Smart URL
              </button>
              <button class="btn mb-2 custom-btn" @click="OpenSmarturl()">
                Add Smart URL
              </button>
            </b-col>
          </b-row>
          <div class="table-responsive datatable-vue">
            <b-table
              class="text-left custom-font-16"
              :fields="smartUrlsFields"
              :per-page="perPage"
              striped
              hover
              :items="smartUrlsData"
              :filter="filter"
              :busy="isBusy"
              stacked="md"
            >
              <template #cell(country)="row">
                <span v-for="(data, i) in row.item.country" :key="i">
                  <span
                    v-if="countryOpt.filter((t) => t.id == data).length > 0"
                    class="badge custom-badge custom-font-18 ml-0"
                    >{{ countryOpt.filter((t) => t.id == data)[0].name }}</span
                  >
                  <span v-else> {{ data }} </span>
                </span>
              </template>
              <template #cell(status)="row">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      :checked="row.item.status"
                      @change="changeStatus(row.item)"
                    />
                    <span
                      class="switch-state"
                      :class="row.item.status ? 'bg-success' : 'bg-danger'"
                    ></span>
                  </label>
                </div>
              </template>
              <template #cell(isProcessed)="row">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      :checked="row.item.isProcessed"
                      disabled
                    />
                    <span
                      class="switch-state"
                      :class="row.item.isProcessed ? 'bg-success' : 'bg-danger'"
                    ></span>
                  </label>
                </div>
              </template>
              <template #cell(url)="row">
                <span target="blank">
                  {{ row.item.url }}
                </span>
              </template>
              <template #cell(action)="row">
                <button
                  size="sm"
                  @click="EditSmarturl(row.item)"
                  class="btn text-blue btn-xs mr-1"
                >
                  <feather type="edit"></feather>
                </button>
                <button
                  size="sm"
                  @click="deleteSmartUrls(row.item._id)"
                  class="btn text-danger btn-xs"
                >
                  <feather type="trash-2"></feather>
                </button>
              </template>
              <!-- New slot for uploadedBy -->
              <template #cell(uploadedByUser)="row">
                <b class="capitalize-text" >{{ row.item.uploadedByUser   }}</b>
              </template>

              <template #table-busy>
                <div class="col-md-12">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <b-col class="mt-1">
            <b-col md="12" class="mt-3 text-right p-0">
              <div class="pagination">
                <a v-show="currentPage > 1" @click="changePage('prev')">« Back</a>
                <a class="active">{{currentPage}}</a>
                <a v-show="currentPage < totalPages" @click="changePage('next')">Next »</a>
              </div>
            </b-col>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="smarturl_model"
      id="smart-url"
      size="lg"
      title="Smart Url"
      class="theme-modal"
      no-close-on-backdrop
      hide-footer
      @hide="getSmarturls()"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-12">
            <b-card class="p-2">
              <form @submit.prevent="Submit()" action="">
                <div class="row">
                  <label>Name</label>
                  <b-form-input
                    v-model="input.name"
                    placeholder="Enter Name"
                    required
                    maxlength="20"
                    minlength="3"
                  ></b-form-input>
                </div>
                <div class="row mt-2">
                  <label>URL</label>
                  <b-form-input
                    v-model="input.url"
                    placeholder="Enter URL"
                    required
                  ></b-form-input>
                </div>
                <div class="row mt-2">
                  <label>Country</label>
                  <multiselect
                    :options="countryOpt"
                    label="name"
                    track-by="id"
                    v-model="countryArray"
                    placeholder="Select Country"
                    :multiple="true"
                    @select="assignCountry"
                  >
                  </multiselect>
                </div>
                <div class="row mt-4 d-flex justify-content-center">
                  <b-button
                    :disabled="isBusy"
                    type="submit"
                    class="col-md-3 col-12"
                    variant="primary"
                    >{{ editSmarturl ? "Edit" : "Submit" }}</b-button
                  >
                </div>
              </form>
            </b-card>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Bulk Smart URL Import Start  -->
    <b-modal
      id="import-monitor"
      size="xl"
      title="Import bulk smart URL"
      class="theme-modal"
      v-model="open_import_smartUrl"
      no-close-on-backdrop
      hide-footer
    >
      <input type="file" id="files" accept=".csv" ref="smartUrlFile" />
      <span v-if="loading">
        <h6 class="sub-title mb-0 text-center">Uploading...</h6>
        <div class="loader-box">
          <div class="loader-30"></div>
        </div>
      </span>
      <span v-else>
        <b-button
          size="sm"
          variant="success"
          class="btn-pill"
          @click="UploadCsv()"
          >Upload</b-button
        >
      </span>
      <div class="p-3">
        <b-row class="mt-2">
          <b-col class="col-md-12">
            <div class="f-20 f-w-600 link_txt" @click="downloadCSV">
              Download file template
            </div>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <b-button @click="open_import_smartUrl = false" color="danger"
          >Close</b-button
        >
      </template>
    </b-modal>
    <!-- Bulk Smart URL Import End  -->
  </div>
</template>

<script>
import SmartUrlApi from "../../../../services/smarturlService";
import NetworkTypeService from "../../../../services/NetworkTypeService";
import Multiselect from "vue-multiselect";
import config from "../../../../services/globalLit";
import SampleCsv from "../../../../services/sampleCsvData";

export default {
  name: "ServiceMapping",
  watch: {
  "filterAllData.name": function () { this.currentPage = 1; this.filterData(); },
  "filterAllData.url": function () { this.currentPage = 1; this.filterData(); },
  "filterAllData.country": { handler() { this.currentPage = 1; this.filterData(); }, deep: true },
},

  components: {
    Multiselect,
  },
  data() {
    return {
      countryOpt: [{ id: "@@@", name: "All country" }],
      input: {
        name: "",
        url: "",
        country: [],
      },
      countryArray: [],
      services: [],

      smarturl_model: false,
      isBusy: false,
      smartUrlsFields: [
        { key: "name", label: "Name", class: "font-weight-bold" },
        { key: "url", label: "Url" },
        { key: "country", label: "Country", sortable: true },
        { key: "uploadedByUser", label: "Uploaded By", sortable: true },
        { key: "isProcessed", label: "Is Processed" },
        { key: "status", label: "Status", sortable: true },
        { key: "createdAt", label: "Created At", sortable: true },
        { key: "action", label: "Action" },
      ],
      open_import_smartUrl: false,
      sampleData: [],
      sampleSmartUrlfields: [],
      totalRows: 1,
      totalPages: 0,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100, 200],
      editSmarturl: false,
      loading: false,

      // filter: { name: "", url: "", country: [] },
      filter: null,
      filterUrl: null,
      filterAllData: {
        name: "",
        url: "",
        country: "",
      },
      nameFilter: null,
      smartUrlsData: [],
      providerList: [],
    };
  },
  mounted() {
    this.getCountry();
    this.init();
    // this.getSmarturls();
  },
  methods: {
    async filterData() {
      console.log("filterAllData => ", this.filterAllData);
      console.log("name", this.filterAllData.name);


      const payload = {};

      payload.page = payload.page || this.currentPage;
      payload.limit = payload.limit || this.perPage;

      if (this.filterAllData.name) {
        payload.name = this.filterAllData.name;
      }

      if (this.filterAllData.url) {
        payload.keyword = this.filterAllData.url;
      }

      if (this.filterAllData.country) {
        payload.country = this.filterAllData.country.id;
      }

      console.log("payload", payload);

      const response = await this.getSmarturls(payload);
    },

    async getSmarturls(payload) {
      this.loading = true;
      this.input = { name: "", url: "", country: "" };

      payload.page = payload.page || this.currentPage;
        payload.limit = payload.limit || this.perPage;

      let response = await SmartUrlApi.getSmarturls(payload);

      console.log("response.data => ", response.data);

      if (response.result) {
        // this.providerList = response.data;
        this.providerList = response.data.map(item => ({
      ...item,
      createdAt: this.formatDate(item.createdAt) 
    }));
        this.smartUrlsData = [...this.providerList];
        this.totalRows = this.smartUrlsData.length;
        this.totalPages = response.pagination ? response.pagination.totalPages : Math.ceil(response.total / this.perPage);
        this.currentPage = response.pagination ? response.pagination.currentPage : payload.page;
        this.perPage = response.pagination ? response.pagination.pageSize : payload.limit;
      }

      this.loading = false;
      console.log("this.smartUrlsData", this.smartUrlsData);
    },




    // <!-- NEW CHANGES START -->
    async changePage(direction) {
      if (direction === 'prev' && this.currentPage > 1) {
        this.currentPage--;
      } else if (direction === 'next' ) {
        this.currentPage++;
      }
      console.log("New Page:", this.currentPage);
      await this.filterData();
    },


    handlePerPageChange(newPerPage) {
      this.perPage = newPerPage;
      this.currentPage = 1;
      this.filterData();
    },

    // <!-- NEW CHANGES END -->

    formatDate(dateString) {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
    },

    async Submit() {
      if (this.validate()) {
        this.isBusy = true;
        var response;
        var payload = { name: this.input.name, url: this.input.url };
        payload.country = this.countryArray.map((element) => {
          return element.id;
        });
        if (this.editSmarturl) {
          payload = {
            suid: this.input._id,
            updateObj: payload,
          };
          response = await SmartUrlApi.editSmarturl(payload);
          this.editSmarturl = false;
        } else {
          response = await SmartUrlApi.addSmarturl(payload);
        }
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
          onComplete: () => {
            this.smarturl_model = false;
            this.getSmarturls();
            this.isBusy = false;
          },
        });
      }
    },
    validate() {
      if (this.input.url == "" || config.isValidUrl(this.input.url) == false) {
        this.$toasted.show("Please enter valid url", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        return false;
      }
      if (this.countryArray.length == 0) {
        this.$toasted.show("Please select country", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
        return false;
      }
      return true;
    },
    OpenSmarturl() {
      this.input = {
        name: "",
        url: "",
        country: [],
      };
      this.editSmarturl = false;
      this.smarturl_model = true;
    },
    async deleteSmartUrls(id) {
      if (confirm("Are you sure you want to delete this smart url?")) {
        let payload = { suid: id };
        let response = await SmartUrlApi.deleteSmartUrls(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
        this.getSmarturls();
      }
    },
    EditSmarturl(data) {
      this.editSmarturl = true;
      this.input = data;
      this.countryArray = [];
      this.countryArray = this.input.country.map((element) => {
        return this.countryOpt.filter((t) => t.id == element)[0];
      });
      this.smarturl_model = true;
    },
    async getCountry() {
      let response = await NetworkTypeService.getCountry();
      this.countryOpt = [{ id: "@@@", name: "All country" }];
      response.data.forEach((element) => {
        this.countryOpt.push({ id: element.alpha_3, name: element.name });
      });
    },
    assignCountry() {
      if (this.countryArray.some((t) => t.id === "@@@")) {
        this.countryArray = [{ id: "@@@", name: "All country" }];
      }
    },
    async changeStatus(data) {
      if (data.status == false) {
        data.status = true;
      } else {
        data.status = false;
      }
      let payload = {
        suid: data._id,
        updateObj: {
          status: data.status,
        },
      };
      let response = await SmartUrlApi.editSmarturl(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(data.status=== false ? "Smarturl Deactivated" : "Smarturl Activated"  || response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 2000,
      });
    },

    async UploadCsv() {
      this.loading = true;
      if (this.$refs.smartUrlFile.files[0]) {
        var file = this.$refs.smartUrlFile.files[0];
        const formData = new FormData();
        formData.append("smartlinkcsv", file);
        let response = await SmartUrlApi.csvUpload(formData);
        if (response.result) {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000,
          });
          this.open_import_smartUrl = false;
          this.getSmarturls();
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
      } else {
        this.$toasted.show("Please select file", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      }
      this.loading = false;
    },
    downloadCSV() {
      this.sampleSmartUrlfields = SampleCsv.sampleSmarturlFields;
      const data = [this.sampleSmartUrlfields];
      const csvContent =
        "data:text/csv;charset=utf-8," +
        data.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "TemplateBulkImportFile.csv");
      document.body.appendChild(link);
      link.click();
    },
    downloadSampleData() {
      this.sampleData = SampleCsv.sampleSmarturlData;
      config.downloadCSV(this.sampleData);
    },

    async init() {
      this.resetFilters();
      this.loading = true;

      let payload = {};
      // this.getSmarturls(payload);
      this.currentPage = 1;
      await this.getSmarturls({ page: this.currentPage, limit: this.perPage });
      // this.getServiceProviderDropDown(payload);

      this.loading = false;
    },

    resetFilters() {
    this.filterAllData = {
      name: "",
      url: "",
      country: "",
    };
  },
  },
};
</script>
<style scoped>
.showBreadcrumbs {
  display: none !important;
}

.capitalize-text {
  text-transform: capitalize;
}

@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-24 {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
</style>